import React from "react";
import { isEmpty } from "lodash";
import Search from "../../components/home/search";
import { Layout } from "../../../../src/components";
import { getOgImage } from "../../utils/functions";

const ShopPage = (props) => {
  const {
    pageContext: {
      page: { title, seo, uri },
      categories,
      categoryName,
      postSearchData: { products, options },
    },
  } = props;

  return (
    <Layout pageTitle={title} seo={seo}>
      <div className="layout">
      {!isEmpty(props.pageContext) ? (
        <Search
          products={products}
          initialProducts={products}
          engine={options}
          category={categoryName}
          categories={categories}
        />
      ) : (
        <div>Something went wrong</div>
      )}
      </div>
    </Layout>
  );
};

export default ShopPage;
